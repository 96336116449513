<template>
  <div class="courses innerpage">
    <Header
      size="medium"
      title="COVID-19 Online Risk Assessment (CORA) Planning Tool"
      name="course-header"
    ></Header>

    <v-container>
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <h1>Quizzes</h1>
        </v-col>
        <!-- <v-col offset-md="4" md="4" align="right">
          <v-btn
            @click="dialog = true"
            color="cora-chip-color-green"
            dark
            rounded
          >
            + add quiz
          </v-btn>
        </v-col> -->
      </v-row>
      <v-divider class="mt-8 mb-3"></v-divider>
      <v-row>
        <v-col>
          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="quizzesHeaders"
            :items="quizzes"
            :hide-default-header="true"
            :hide-default-footer="true"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-5 mb-5 align-center"
          >
            <template v-slot:item.edit="{ item }">
              <v-icon class="pa-6 " @click="goToUrl('quizquestions', item.id)">
                mdi-playlist-edit
              </v-icon>
            </template>
            <!-- <template v-slot:item.delete="{ item }">
              <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
            </template> -->
            <template v-slot:item.start="{ item }">
              <v-btn
                class=""
                color="primary"
                @click="goToUrl('quizsingle', item.id)"
                rounded
              >
                Launch
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn
            @click="backToCourses()"
            class="back-to-blue"
            color="cora-chip-color-blue"
            dark
            rounded
          >
            back to courses
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <h3 class="text-h4">{{ formTitle }}</h3>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      filled
                      v-model="editedItem.name"
                      label="Quiz Name"
                      :rules="[v => !!v || 'name is required']"
                      required
                    ></v-text-field>
                    <v-text-field
                      filled
                      v-model="editedItem.type"
                      label="Quiz Type"
                      :rules="[v => !!v || 'type is required']"
                      required
                    ></v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="saveItem(editedItem)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import CoursesService from "../services/courses.service";

export default {
  components: {
    Header
  },
  data: () => ({
    course: {},
    breadcrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/"
      },
      {
        text: "Courses",
        disabled: false,
        href: "/mycourses"
      },
      {
        text: "Quizzes",
        disabled: true,
        href: "/chapterquizzes"
      }
    ],
    quizzesHeaders: [
      {
        text: "ID",
        value: "id",
        width: "30%"
      },
      {
        text: "Quiz Name",
        value: "name",
        width: "30%"
      },
      {
        text: "Quiz Type",
        value: "type",
        width: "25%"
      },
      {
        text: "",
        value: "edit",
        width: "50%"
      },
      {
        text: "",
        value: "delete",
        width: "50%"
      },
      {
        text: "Start",
        value: "start",
        width: "50%"
      }
    ],
    quizzes: [],
    editedItem: {
      id: "",
      name: "",
      type: ""
    },
    originalItem: {
      id: "",
      name: "",
      type: ""
    },
    category_id: null,
    apiError: "",
    dataLoading: false,
    isLoaded: false,
    dialog: false,
    editedIndex: -1,
    snackbar: false,
    valid: true,
    alert: ""
  }),

  computed: {
    formTitle: {
      get() {
        return this.editedIndex === -1 ? "Create New Quiz" : "Edit Quiz";
      },
      set(newTitle) {
        return newTitle;
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    orgId() {
      return this.$store.state.auth.user.user.organizations.id;
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.initialize();
    }
  },

  methods: {
    async initialize() {
      CoursesService.getCourse(this.$route.params.id).then(
        response => {
          (this.isLoaded = true), (this.course = response.data.data);
          this.quizzes = this.course.custom_quizzes;
          this.category_id = this.course.category_id;
        },
        error => {
          this.apiError =
            (error.response && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    editItem(item) {
      this.formTitle = "Edit Quiz";
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.quizzes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    saveItem(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          // update item
          const data = {
            id: item.id,
            name: item.name,
            type: item.type
          };

          // const updatedCourse = await ChaptersService.update({
          //   id: item.id,
          //   data: data
          // });
          this.editedItem = data;
          this.quizzes.splice(this.editedIndex, 1, this.editedItem);
          this.showSnackbar("The Course has been updated successfully");
        } else {
          // create item

          const data = {
            id: this.randomNumber(),
            name: item.name,
            type: item.type
          };

          // const newCourse = await ChaptersService.create({
          //   data: data
          // });

          this.quizzes.push(data);

          this.showSnackbar("The Course has been added successfully");
        }
      }
      this.dialog = false;
    },
    randomNumber() {
      return Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    },
    deleteItem(item) {
      const index = this.quizzes.indexOf(item);
      confirm("Are you sure you want to delete this quiz?") &&
        this.quizzes.splice(index, 1);

      // await ChaptersService.remove({
      //   id: item.id
      // });

      this.showSnackbar(item.title + " has been deleted");
    },
    showSnackbar(msg) {
      this.alert = msg;
      this.snackbar = true;
    },
    backToCourses() {
      window.location.href = "/mycourses";
    },
    goToUrl(myUrl, quiz_id) {
      this.$router.push({
        path: "/" + myUrl + "/" + quiz_id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.back-to-blue {
  background-color: #c5e1e9 !important;
  color: #2f91ae;
}
.courses-card {
  padding-bottom: 10px;
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
